.login-outer {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.login-outer-left {
  flex: 13;
  background-image: url('./assets/images/wickedbackground.svg');
  background-position: 50%;
  background-size: cover;
}

.login-outer-right {
  flex: 7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.login-logo {
  background-image: url('./assets/images/logo-words.png') ;
  height: 200px;
  width: 100%;
  background-position: 50%;
  background-size: 300px;
  background-repeat: no-repeat;
}

.login-form-textfield {
  width: 300px
}
